import type React from 'react';
import { useEffect, useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import classNames from 'classnames';
import Head from 'next/head';
import { useRouter } from 'next/router';

import NavTab from '@/components/shared/nav-tab/NavTab';
import TdLogo from '@/components/shared/td-logo/TdLogo';
import FlexBox from '@/components/uikit/flex-box/FlexBox';
import { LinkTrackingCtx } from '@/components/uikit/link/TdLink';
import type { LinkButtonProps } from '@/components/uikit/link-button/LinkButton';
import LinkButton from '@/components/uikit/link-button/LinkButton';
import { NavTabs } from '@/shared/header/HeaderItems';
import { getMainDomain } from '@/utils/cookies.util';
import keyboardNavUtil from '@/utils/keyboard-nav.util';

import styles from './Header.module.scss';


export type HeaderProps = {
  startTrialLink?: string | undefined;
  pageName: string;
  onStartTrialClick?: () => void;
}

const Header: React.FC<HeaderProps> = (
  {
    startTrialLink = '/register',
    pageName,
    onStartTrialClick,
  },
) => {
  const router = useRouter();
  const [lastClickedNavTab, setLastClickedNavTab] = useState<string>('');
  const [openedTabs, setOpenedTabs] = useState<Map<string, boolean>>(new Map());
  const [mobileExpanded, setMobileExpanded] = useState(false);
  const [currentPathName, setCurrentPathName] = useState('');

  useEffect(() => {
    const handleDocumentClick = (ev: MouseEvent) => {
      if (!ev.composedPath().some((target: EventTarget) => target instanceof HTMLElement && target.getAttribute('role') === 'banner')) {
        setLastClickedNavTab('');
        setMobileExpanded(false);
        setOpenedTabs(new Map());
      }
    };

    setCurrentPathName(router.asPath);
    window.document.addEventListener('click', handleDocumentClick);

    return () => window.document.removeEventListener('click', handleDocumentClick);
  }, [router.asPath]);


  const handleNavTabClick = (tabTitle: string) => {
    if (lastClickedNavTab === tabTitle) {
      setLastClickedNavTab('');
      return;
    }

    setLastClickedNavTab(tabTitle);

  };

  const handleNavTabClickMobile = (tabTitle: string) => {
    if (openedTabs.get(tabTitle)) {
      setOpenedTabs(prevState => {
        const newState = new Map(prevState);
        newState.delete(tabTitle);
        return newState;
      });
    } else {
      setOpenedTabs(prevState => {
        const newState = new Map(prevState);
        newState.set(tabTitle, true);
        return newState;
      });
    }
  };

  const handleMobileMenuClick = () => {
    setMobileExpanded(prevState => !prevState);
    if (mobileExpanded) setOpenedTabs(new Map());
  };

  const handleKeyDownMenuBar = (evt: React.KeyboardEvent) => {
    const target = evt.target as HTMLElement;

    if (evt.key === 'ArrowRight') {
      keyboardNavUtil.navigateForward(evt.nativeEvent);
    } else if (evt.key === 'ArrowLeft') {
      keyboardNavUtil.navigateBackward(evt.nativeEvent);
    } else if (evt.key === 'ArrowDown') {
      if (target.getAttribute('aria-haspopup') === 'true'
        && target.getAttribute('aria-expanded') === 'false') {
        evt.preventDefault();

        target.setAttribute('aria-expanded', 'true');
        const firstItem = target.querySelector('[role="menuitem"]') as HTMLElement;
        firstItem.focus();
        setLastClickedNavTab(target.getAttribute('aria-label') || '');
      } else if (target.getAttribute('aria-haspopup') === 'true'
        && target.getAttribute('aria-expanded') === 'true') {
        evt.preventDefault();
        const firstItem = target.querySelector('[role="menuitem"]') as HTMLElement;
        firstItem.focus();
      }
    } else if (evt.key === 'Escape' || evt.key === 'Tab') {
      const tabElm = evt.nativeEvent.composedPath().find(
        target =>
          target instanceof HTMLElement
          && target.getAttribute('role') === 'menuitem'
          && target.getAttribute('aria-haspopup') === 'true',
      ) as HTMLElement;
      if (!target.getAttribute('aria-haspopup')) tabElm.focus();

      setLastClickedNavTab('');
    }
  };

  function handleStartTrialBtnClick() {
    if (onStartTrialClick) onStartTrialClick();
  }

  const StartTrialBtn: React.FC<Omit<LinkButtonProps, 'href'>> = ({ onClick: _, ...props }) => (
    <LinkButton
      href={ `${ startTrialLink }?homecta=pg-${ pageName }-topnav` }
      onClick={ handleStartTrialBtnClick }
      noBorderCorner="none"
      endIcon={ <ArrowForwardIcon width={ 20 } height={ 20 } fontSize="inherit" /> }
      { ...props }
    >
      Start a trial
    </LinkButton>
  );

  const MobileHead: React.FC<{ onMenuClick: () => void, isExpanded: boolean }> = (
    { onMenuClick, isExpanded },
  ) => (
    <FlexBox
      display="none"
      justifyContent="space-between" alignItems="center"
      className={ styles.Header__MobileHead }
    >
      <TdLogo
        width={ 141 } height={ 32 }
        data-testid="header-logo-mobile"
      />
      <FlexBox alignItems="center" columnGap={ 24 }>
        <LinkButton
          anchorClassName={ styles.Header__RightSide__ViewDemoBtn_Mobile }
          href={ `https://www.timedoctor.com/book-demo?homecta=pg-${ pageName }-topnav` }
          variant="contained" noBorderCorner="none"
          sx={{ fontSize: '20px' }} color="blue"
          noHover={ false }
          endIcon={ <ArrowForwardIcon width={ 20 } height={ 20 } fontSize="inherit" /> }
        >
          View a demo
        </LinkButton>
        <button
          data-testid="burger-btn"
          className={ classNames(styles.Header__MobileHead__MenuIcon, {
            [styles.menuExpanded]: isExpanded,
          }) }
          onClick={ onMenuClick }
        >
          <span />
        </button>
      </FlexBox>
    </FlexBox>
  );

  return (
    <header
      role="banner"
      className={ classNames(styles.Header, {
        [styles.expanded]: mobileExpanded,
      }) }
      data-expanded={ mobileExpanded }
    >
      <LinkTrackingCtx.Provider value={{ location: 'topnav', category: 'TopNav', linkName: 'View Demo' }}>
        <Head>
          <meta
            name="viewport" content="height=device-height,
                      width=device-width, initial-scale=1.0,
                      maximum-scale=1.0, user-scalable=no" />
        </Head>
        <MobileHead onMenuClick={ handleMobileMenuClick } isExpanded={ mobileExpanded } />
        <div className={ styles.Header__Content }>
          <FlexBox className={ styles.Header__LeftSide }>
            <TdLogo
              width={ 190 } height={ 36 }
              className={ styles.Header__LeftSide__Logo }
              data-testid="header-logo-desktop"
            />
            <FlexBox
              as="ul"
              alignItems={ 'flex-end' }
              className={ styles.Header__LeftSide__NavTabsBox }
              attributes={{ role: 'menubar', onKeyDown: handleKeyDownMenuBar }}>
              { NavTabs.map(({ title, ...others }, i) => (
                <NavTab
                  key={ title } title={ title }
                  onTabClick={ handleNavTabClick }
                  isExpanded={ (lastClickedNavTab === title) }
                  isActive={ (lastClickedNavTab === title) || currentPathName === others.href }
                  tabIndex={ i === 0 ? 0 : -1 }
                  { ...others }
                />
              )) }
            </FlexBox>
            <FlexBox
              as="ul"
              className={ styles.Header__LeftSide__NavTabsBoxMobile }
            >
              { NavTabs.map(({ title, ...others }) => (
                <NavTab
                  key={ title } title={ title }
                  onTabClick={ handleNavTabClickMobile }
                  isExpanded={ !!openedTabs.get(title) }
                  isActive={ !!openedTabs.get(title) }
                  { ...others }
                />
              )) }
            </FlexBox>
          </FlexBox>
          <FlexBox alignItems={ 'flex-end' } className={ styles.Header__RightSide }>
            <LinkButton
              anchorClassName={ styles.Header__RightSide__LoginBtn }
              href={ `https://2.${ getMainDomain() }/login` }
              textUnderline={ false } color="blue"
              sx={{ padding: '8px 0', fontSize: '20px', fontWeight: 600 }}
              noHover={ false }
            >
              Login
            </LinkButton>
            <LinkButton
              anchorClassName={ styles.Header__RightSide__ViewDemoBtn }
              href={ `https://www.timedoctor.com/book-demo?homecta=pg-${ pageName }-topnav` }
              variant="contained" noBorderCorner="none"
              sx={{ fontSize: '20px' }} color="blue"
              noHover={ false }
              endIcon={ <ArrowForwardIcon width={ 20 } height={ 20 } fontSize="inherit" /> }
            >
              View a demo
            </LinkButton>
            <StartTrialBtn
              sx={{ fontSize: '20px' }}
              anchorClassName={ styles.Header__RightSide__TrialBtn }
              variant="outlined"
            />
          </FlexBox>
        </div>
      </LinkTrackingCtx.Provider>
    </header>
  );
};

export default Header;
