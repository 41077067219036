import type React from 'react';
import { useRef } from 'react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import classNames from 'classnames';

const FooterNavColumn: React.FC<{ title: string, listClasses?: string, children?: React.ReactNode; }> = (
  { children, title, listClasses = '' },
) => {
  const accordionHeaderRef = useRef<HTMLDivElement>(null);

  function onAccordionHeaderClick() {
    if (window.innerWidth > 767) return;

    const accordionElm = accordionHeaderRef?.current?.parentElement;

    if (accordionElm?.classList.contains('expanded')) {
      accordionElm?.classList.remove('expanded');
    } else {
      accordionElm?.classList.add('expanded');
    }
  }

  return (
    <nav className="nav-column">
      <div
        ref={ accordionHeaderRef }
        className="nav-column__header"
        data-testid={ `footer-accordion-header-${ title.toLowerCase() }` }
        onClick={ onAccordionHeaderClick }>
        <h5 className="nav-column__header__title">{ title }</h5>
        <button type="button" className="nav-column__header__expand-btn">
          <ExpandMore sx={{ fontSize: 24 }} />
        </button>
      </div>
      <ul className={ classNames('nav-column__content', listClasses) }>
        { children }
      </ul>
    </nav>
  );
};

export default FooterNavColumn;
