import type { FC } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import MuiTypography from '@mui/material/Typography';
import type { SbBlokData } from '@storyblok/js';
import { storyblokEditable } from '@storyblok/react';

import type { TextStoryblok } from '@/sb-types';


type TextBlokProps = {
  blok: SbBlokData<TextStoryblok>
}

type UserStyles = Pick<SbBlokData<TextStoryblok>, 'marginObj' | 'fontWeight' | 'fontConfig' | 'config' | 'customColor'>


const propsToForward: (keyof TextStoryblok)[] = ['variant'];

const StyledMuiTypography = styled(MuiTypography, {
  shouldForwardProp: (prop) => isPropValid(prop) || propsToForward.includes(prop as keyof TextStoryblok),
})<UserStyles>
(({ theme, config, marginObj, fontWeight, fontConfig, customColor }) => ({
  fontWeight,

  ...config,
  ...marginObj,
  ...customColor,
  ...fontConfig,

  ...theme.utils.objStyleBreakpoints(config, marginObj, customColor, fontConfig),
}));

export const TextBlok: FC<TextBlokProps> = ({ blok: { component, content, ...allProps }}) => (
  <StyledMuiTypography
    { ...allProps }
    { ...storyblokEditable({ component, content, ...allProps }) }
    { ...(allProps.htmlTag ? { component: allProps.htmlTag } : null) }
  >
    { content }
  </StyledMuiTypography>
);
