import { css } from '@emotion/react';

export const styles = {
  FooterContainer: css`
    display: flex;
    justify-content: center;

    @media (max-width: 1200px) {
      display: contents;
    }
  `,
  Root: css`
    display: flex;
    gap: 30px;

    @media (max-width: 1200px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 30px;
    }

    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      gap: 24px;
    }
  `,

  ListContainer: css`
    display: flex;
    flex-direction: column;
    gap: 60px;

    @media (max-width: 1200px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 1023px) {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }

    @media (max-width: 767px) {
      gap: 24px;
    }
  `,

  ListItem: css`
    min-width: 210px;

    @media (max-width: 1200px) {
      width: 100%;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  `,

  IconSection: css`
    display: flex;
    gap: 10px;
  `,
}
