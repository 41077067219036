import type { FC } from 'react';
import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import type { TdInputProps } from '@/components/uikit/Input';
import { ValidityAdornment } from '@/components/uikit/Input';
import ControlledInput from '@/components/uikit/Input/ControlledInput';
import { EMPTY_ERR_MSG, sharedInputProps } from '@/components/uikit/Input/PrivateComponents/TdFormControl';
import { UserRegisteredMsg } from '@/components/uikit/Input/PrivateComponents/UserRegisteredMsg';
import { FormInputCtx } from '@/components/uikit/Input/TdInput';
import { useAppSelector } from '@/hooks';
import type { SignUpFormData } from '@/types/signup.types';
import { getComponentToRender, isBoxedContainer } from '@/utils/input-component.util';

export const TdInputEmail: FC<TdInputProps> = (props) => {
  const { watch, formState: { errors }} = useFormContext<SignUpFormData>();
  const { touchedFields, asyncEmailErr, setAsyncEmailErr } = useContext(FormInputCtx);
  const [hasEmailErr, setHasEmailErr] = useState(false);
  const apiErrorMessage = useAppSelector(state => state.signup.errorMessage);
  const { email } = touchedFields;
  const watchValue = watch('email');
  const isTouched = !!email;
  const hasErrorAndTouched = isTouched && hasEmailErr && !!watchValue;

  const customErrMsg = {
    loginExists: <UserRegisteredMsg email={ watchValue } />,
    neverbounce: <>Please enter a valid email address!</>,
    loginExistsTdc: <UserRegisteredMsg email={ watchValue } />,
    apiError: <>{ apiErrorMessage }</>,
  };

  useEffect(() => {
    setHasEmailErr(!!asyncEmailErr)
  }, [asyncEmailErr]);

  useEffect(() => {
    setHasEmailErr(false);
    setAsyncEmailErr(undefined);
  }, [watchValue, setAsyncEmailErr]);

  const boxedContainer = isBoxedContainer(props.containerType);
  const ComponentToRender = getComponentToRender(props.containerType);
  const placeholderText = props.placeholder ?? props.label;

  return (
    <ComponentToRender
      variety="email"
      rounded={ props.rounded }
      labelMode={ props.labelMode }
      errorDisplay={ props.errorDisplay }
      render={ ({ field }) => (
        <ControlledInput
          { ...field }
          { ...sharedInputProps(props) }
          placeholder={ placeholderText }
          variety="email"
          variant={ props.variant }
          id={ props.inputId }
          type="email"
          aria-errormessage="err-email"
          endAdornment={
            !boxedContainer &&
            <ValidityAdornment variety="email" additionalErrCond={ hasErrorAndTouched } />
          }
        />
      ) }
      customErrCond={ hasErrorAndTouched || (isTouched && !!errors.email?.message) }
      CustomErrorMsg={
        (isTouched && errors.email?.message) ||
        (hasErrorAndTouched && asyncEmailErr && customErrMsg[asyncEmailErr]) ||
        EMPTY_ERR_MSG
      }
      { ...props }
    />
  );
};
