import type { FC, HTMLAttributes, KeyboardEvent } from 'react';
import { useRef } from 'react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';

import NavItem from '@/components/shared/nav-item/NavItem';
import RenderIf from '@/components/shared/render-if/RenderIf';
import BasicCard from '@/components/uikit/basic-card/BasicCard';
import FlexBox from '@/components/uikit/flex-box/FlexBox';
import TdLink, { LinkTrackingCtx } from '@/components/uikit/link/TdLink';
import type { NavColumnData, NavTabData } from '@/types/header.types';
import keyboardNavUtil from '@/utils/keyboard-nav.util';

import styles from './NavTab.module.scss';

type NavTabProps = {
  isActive?: boolean;
  isExpanded?: boolean;
  onTabClick?: (tabTitle: string) => void;
} & NavTabData & HTMLAttributes<HTMLLIElement>

type DropdownBoxProps = {
  hasFullWidth: boolean;
} & NavTabData & HTMLAttributes<HTMLDivElement>

const NavTab: FC<NavTabProps> = (
  {
    title,
    mode = 'dropdown',
    dropdownMode = 'auto',
    href,
    isActive = false,
    isExpanded = false,
    navColumns,
    onTabClick = () => ({}),
    tabIndex,
    ...allAttributes
  },
) => (
  <li
    className={ classNames(styles.NavTab, {
      [styles.expanded]: isExpanded,
      [styles.active]: isActive,
    }) }
    role="none"
    { ...allAttributes }
  >
    <RenderIf condition={ mode === 'dropdown' }>
      <FlexBox
        alignItems="center"
        className={ styles.NavTabBox }
        attributes={{
          role: 'menuitem',
          'aria-haspopup': true,
          'aria-label': title,
          'aria-expanded': isExpanded,
          tabIndex,
        }}
      >
        <FlexBox
          className={ styles.NavTabBox__Box }
          alignItems="center"
          attributes={{
            onClick: () => { onTabClick(title); },
          }}
        >
          <Typography
            variant="body2"
            className={ classNames(styles.NavTab__Title, {
              [styles.active]: isActive,
            }) }
          >
            { title }
          </Typography>
          <ExpandMore
            className={ classNames(styles.NavTab__ExpandIcon, {
              [styles.active]: isActive,
            }) } sx={{ fontSize: 20 }} />
        </FlexBox>
        <DropDownBox
          hasFullWidth={ dropdownMode === 'fullWidth' }
          navColumns={ navColumns } title={ title }
        />
      </FlexBox>
    </RenderIf>
    <RenderIf condition={ mode === 'link' && !!href }>
      <TdLink
        href={ href! }
        role="menuitem" aria-label={ title }
        tabIndex={ tabIndex }
        className={ styles.NavTabBox__Link }
      >
        <Typography variant="body2" className={ styles.NavTab__Title }>
          { title }
        </Typography>
      </TdLink>
    </RenderIf>
  </li>
);

const DropDownBox: FC<DropdownBoxProps> = ({ hasFullWidth, navColumns, title: tabTitle }) => {
  const handleKeyDown = (evt: KeyboardEvent) => {
    if (evt.key === 'ArrowRight') {
      keyboardNavUtil.megaMenuNavigation('forward', evt);
    } else if (evt.key === 'ArrowLeft') {
      keyboardNavUtil.megaMenuNavigation('backward', evt);
    }
  };

  return (
    <div
      className={ classNames(styles.NavTabBox__Dropdown, {
        [styles.FullWidth]: hasFullWidth,
      }) }>
      <BasicCard
        className={ styles.NavTabBox__Dropdown__Box }
        contentClass={ styles.NavTabBox__Dropdown__CardContent }
        borderRadius="16px" shadowStyle="default"
        noBorderCorner="topRight">
        <FlexBox
          as="nav"
          justifyContent="space-between"
          className={ styles.NavTabBox__Dropdown__Box__Content }
          attributes={{ onKeyDown: handleKeyDown }}
        >
          {
            navColumns.map(({ title, items }, i) => (
              <LinkTrackingCtx.Provider
                key={ `nav-column-${ tabTitle }-${ i }` }
                value={{ location: 'topnav', category: `TopNav-${ tabTitle }` }}>
                <NavColumn
                  key={ `nav-column-${ tabTitle }-${ i }` }
                  items={ items } title={ title } />
              </LinkTrackingCtx.Provider>
            ))
          }
        </FlexBox>
      </BasicCard>
    </div>
  );
};

const NavColumn: FC<NavColumnData> = ({ title, items }) => {
  const ulRef = useRef<HTMLUListElement | null>(null);

  const handleKeyDown = (evt: KeyboardEvent) => {
    if (evt.key === 'ArrowDown') {
      keyboardNavUtil.navigateForward(evt.nativeEvent);
      evt.stopPropagation();
    } else if (evt.key === 'ArrowUp') {
      keyboardNavUtil.navigateBackward(evt.nativeEvent);
    } else if (evt.key === 'Escape' || evt.key === 'Tab') {
      if (ulRef.current) {
        keyboardNavUtil.resetTabIndex(evt.target as HTMLElement, ulRef.current);
      }
    }
  };

  return (
    <div className={ styles.NavTabBox__NavColumn } role="group">
      <RenderIf condition={ !!title }>
        <h6
          className={ styles.NavTabBox__NavColumn__Title }
        >
          { title }
        </h6>
      </RenderIf>
      <ul role="menu" ref={ ulRef } onKeyDown={ handleKeyDown }>
        {
          items.map(({ title, itemName, href }, i) => (
            <NavItem
              key={ `nav-column-${ itemName }-${ i }` }
              href={ href }
              title={ title } itemName={ itemName }
              tabIndex={ i === 0 ? 0 : -1 }
            />
          ))
        }
      </ul>
    </div>
  );
};

export default NavTab;
