import type { FC } from 'react';
import styled from '@emotion/styled';
import type { SbBlokData } from '@storyblok/js';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import type { ColumnStoryblok } from '@/sb-types';


type ColumnBlokProps = {
  blok: SbBlokData<ColumnStoryblok>;
}

const Column = styled.div<SbBlokData<ColumnStoryblok>>(({
  theme,
  sizeObj,
  marginObj,
  paddingObj,
  gapObj,
  config,
  bgColor,
  flex,
  borderObj,
}) => ({
  display: 'flex',
  flexDirection: 'column',

  flex,
  ...config,
  ...sizeObj,
  ...marginObj,
  ...paddingObj,
  ...gapObj,
  ...bgColor,
  ...borderObj,

  ...theme.utils.objStyleBreakpoints(config, sizeObj, marginObj, paddingObj, gapObj, borderObj),
}));

export const ColumnBlok: FC<ColumnBlokProps> = ({ blok }) => (
  <Column { ...blok } { ...storyblokEditable(blok) }>
    {
      blok.content?.map((nestedBlok) => (<StoryblokComponent blok={ nestedBlok } key={ nestedBlok._uid } />))
    }
  </Column>
);
