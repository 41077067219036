import type React from 'react';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';

import TdLink from '@/components/uikit/link/TdLink';
import { NavItem } from '@/types/header.types';

import styles from './NavItem.module.scss';

type NavItemProps = NavItem & React.HTMLAttributes<HTMLAnchorElement>

const NavItem: React.FC<NavItemProps> = ({ title, itemName, href, className, ...all }) => (
  <li role="none">
    <TdLink
      href={ href }
      role="menuitem"
      className={ classNames(className, styles.NavItem) }
      aria-label={ title }
      { ...all }
    >
      <Typography
        mb={ 1 } lineHeight="18px"
        className={ styles.NavItem__ItemName }>
        { itemName }
      </Typography>
    </TdLink>
  </li>
);

export default NavItem;
