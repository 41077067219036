import { css } from '@emotion/react';
import type { Theme } from '@mui/material/styles';

const tdInputBoxStyles = {
  inputBoxRoot: (theme: Theme) => css`
    &[data-has-error="true"] {
      --field-border-color: ${ theme.palette.error.light };
    }

    &[data-is-valid="true"] {
      --field-border-color: ${ theme.palette.secondary.main };
    }

    &[data-is-neutral="true"] {
      --field-border-color: ${ theme.palette.neutral.lightest };

    }

    & .MuiFormLabel-root.Mui-focused,
    & .MuiFormLabel-root.MuiFormLabel-filled {
      transform: unset;
    }

    & .MuiInputBase-root {
      color: #B3AEAB;
      width: 100%;
      //padding: 0 20px;
      border-radius: 15px;
      border: 1px solid #B3AEAB;
      background: #FFF;

      & fieldset {
        border: 1px solid var(--field-border-color);
      }

      &.Mui-focused fieldset, &:hover fieldset {
        border: 1px solid #04D464 !important;
      }

      input {
        padding: 5px 15px;
        border-radius: 0;
        color: #342B27;

        font-size: ${ theme.typography.body2.fontSize } !important;
        font-style: normal;
        font-weight: ${ theme.typography.body2.fontWeight } !important;
        line-height: ${ theme.typography.body2.lineHeight } !important;

        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 1000px white inset;
        }
        &:focus::placeholder {
          color: transparent;
        }
      }

      &::before,
      &::after {
        width: calc(100% - 40px);
        margin: 0 auto;
      }
    }
    .Mui-error {
      margin-bottom: 0.625rem;
      color: ${ theme.palette.error.light };
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  `,
  inputLabel: (theme: Theme) => css({
    fontSize: theme.typography.body3.fontSize,
    fontStyle: theme.typography.body3.fontStyle,
    fontWeight: theme.typography.body3.fontWeight,
    lineHeight: theme.typography.body3.lineHeight,
    color: '#342B27',
    paddingBottom: '5px',
    textAlign: 'left',
  }),
};

export default tdInputBoxStyles;
