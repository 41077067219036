import type { ThemeOptions } from '@mui/material';

export const palette: ThemeOptions['palette'] = {
  primary: {
    main: '#0066FF',
    light: 'rgba(39, 194, 108, 0.07)',
    dark: 'var(--color-primary-dark)',
    contrastText: '#fff',
  },
  secondary: {
    main: '#04D464',
    contrastText: '#fff',
  },
  saffron: {
    main: '#f7b82f',
    contrastText: '#fff',
  },
  blue: {
    main: '#0066FF',
    light: '#b1b7f0',
    contrastText: '#fff',
  },
  neutral: {
    main: '#6d6f78',
    light: '#edeef2',
    lighter: '#a4a7b2',
    lightest: '#d9dbe9',
    lightDark: '#8c8f99',
    dark: '#4c4d5e',
    contrastText: '#fff',
  },
  white: {
    main: '#fff',
    light: '#fff',
    dark: '#fff',
    contrastText: '#2c2e3c',
  },
  info: {
    main: '#2c2e3c',
    light: '#585a6e',
    lightDark: '#717e95',
    lighter: '#a4a7b2',
    contrastText: '#fff',
  },
  error: {
    main: '#d32f2f',
    light: '#f22f42',
  },
  primaryCustom: {
    main: '#0066FF',
    light: 'rgba(39, 194, 108, 0.07)',
    dark: 'var(--color-primary-dark)',
    contrastText: '#fff',
  },
  secondaryCustom: {
    light: '#eb4e29',
    main: '#04D464',
    dark: '#eb4e29',
    contrastText: '#fff',
  },
  black: {
    main: '#000',
    light: '#000',
    dark: '#000',
    contrastText: '#fff',
  },
};
