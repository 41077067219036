import type { ThemeOptions } from '@mui/material';

import { breakpoints } from './breakpoints.theme';

const tabletBp = `@media (max-width: ${ breakpoints!.values!.md - 1 }px)`;

const mobileBp = `@media (max-width: ${ breakpoints!.values!.sm - 1 }px)`;

export const typography: ThemeOptions['typography'] = {
  h1: {
    fontSize: '4rem',
  },
  h2: {
    fontSize: '3rem',
    fontWeight: 300,
    lineHeight: '3.5rem',
    textAlign: 'center',
    [mobileBp]: {
      fontSize: '2rem',
      lineHeight: '2.25rem',
    },
  },
  h3: {
    fontSize: '2.125rem',
    fontWeight: 700,
    lineHeight: '2.5rem',
    letterSpacing: '0.25px',
    [mobileBp]: {
      fontSize: '1.625rem',
      lineHeight: '2.125rem',
    },
  },
  h4: {
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: '32px',
    [mobileBp]: {
      fontSize: '1.125rem',
      lineHeight: '26px',
    },
  },
  h5: {
    fontSize: '1.5rem',
    fontWeight: 700,
    lineHeight: '1.75rem',
  },
  h6: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '28px',
    letterSpacing: '0.44px',
    color: '#6d6f78',
  },
  body1: {
    fontSize: '1.125rem',
    fontWeight: 400,
    lineHeight: '28px',
    letterSpacing: '0.14px',
    '@media (max-width:1023px)': {
      fontSize: '1rem',
      lineHeight: '24px',
    },
    '@media (max-width:767px)': {
      fontSize: '0.875rem',
      lineHeight: '20px',
    },
  },
  body2: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0.29px',
    color: '#6d6f78',
  },
  body3: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: '#6d6f78',
  },
  subtitle1: {
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: '36px',
    [mobileBp]: {
      fontSize: '1rem',
      lineHeight: '24px',
    },
  },
  subtitle2: {
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: '28px',
  },
  caption: {
    display: 'block',
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.4px',
  },
  captionMedium: {
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.75px',
    textTransform: 'uppercase',
  },
  tabs: {
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.75px',
    textTransform: 'uppercase',
  },
  inputText: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.3px',
  },
};

export const web3Typography: ThemeOptions['typography'] = {
  h1: {
    fontSize: '72px',
    fontWeight: 900,
    fontStyle: 'normal',
    lineHeight: '72px',
    textAlign: 'inherit',
    [tabletBp]: {
      fontSize: '56px',
      lineHeight: '56px',
    },
    [mobileBp]: {
      fontSize: '44px',
      lineHeight: '44px',
    },
  },
  h2: {
    fontSize: '64px',
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: '64px',
    [tabletBp]: {
      fontSize: '48px',
      lineHeight: '48px',
    },
    [mobileBp]: {
      fontSize: '36px',
      lineHeight: '36px',
    },
  },
  h3: {
    fontSize: '48px',
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: '48px',
    [tabletBp]: {
      fontSize: '40px',
      lineHeight: '40px',
    },
    [mobileBp]: {
      fontSize: '32px',
      lineHeight: '32px',
    },
  },
  h4: {
    fontSize: '36px',
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: '36px',
    [tabletBp]: {
      fontSize: '28px',
      lineHeight: '28px',
    },
    [mobileBp]: {
      fontSize: '24px',
      lineHeight: '24px',
    },
  },
  h5: {
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: '24px',
    [tabletBp]: {
      fontSize: '20px',
      lineHeight: '20px',
    },
  },
  h6: {
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: '20px',
    [tabletBp]: {
      fontSize: '16px',
      lineHeight: '16px',
    },
  },
  body: {
    fontSize: '20px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '30px',
    [tabletBp]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  // body1 is used for body1 Emphasis
  body1: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '30px',
    [tabletBp]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  body2: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    [tabletBp]: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  // body3 is used for body2 Emphasis
  body3: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    [tabletBp]: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  subtitle1: typography.subtitle1,
  subtitle2: typography.subtitle2,
  display: {
    fontSize: '3rem',
    fontWeight: 300,
    lineHeight: '3.5rem',
    textAlign: 'center',
    [mobileBp]: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
    },
  },
  cta: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.25rem',
  },
  tabs: {
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: '1rem',
    letterSpacing: '0.64px',
    textTransform: 'uppercase',
  },
  button: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24px',
    [tabletBp]: {
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
  inputText: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px',
    [tabletBp]: {
      lineHeight: '14px',
    },
  },
};
