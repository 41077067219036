import type { ReactNode } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { TypographyProps } from '@mui/material/Typography';
import MuiTypography from '@mui/material/Typography';
import { StoryblokComponent } from '@storyblok/react';
import { MARK_LINK, NODE_HEADING, NODE_LI, NODE_PARAGRAPH, NODE_UL, render } from 'storyblok-rich-text-react-renderer';

import TdLink from '@/components/uikit/link/TdLink';
import type { RichTextStoryblok, RichtextStoryblok } from '@/sb-types';

type ParagraphProps = {
  paragraphConfig?: TextConfig;
  paragraphOptions?: RichTextStoryblok['paragraphOptions'];
  variant?: TypographyProps['variant'];
}

const styles = {
  UList: css`
    padding-left: 28px;
  `,
  ListItem: css`
    margin-bottom: 5px;
    &::marker {
      font-size: 1rem;
    }

    p {
      margin: 0;
    }
  `,
  Paragraph: css`
    margin-bottom: 10px;
  `,
};

const propsToForward: (keyof TypographyProps)[] = ['variant'];

const Paragraph = styled(MuiTypography, {
  shouldForwardProp: (prop) => isPropValid(prop) || propsToForward.includes(prop as keyof TypographyProps),
})<ParagraphProps>(({ paragraphConfig, paragraphOptions, theme }) => css({
  '&:empty': {
    marginTop: '1rem',
  },

  '&.RichText-paragraph': {
    ...paragraphConfig,
    ...paragraphOptions,
    ...theme.utils.objStyleBreakpoints(paragraphConfig, paragraphOptions),
  },
}));

export const renderRichText = (
  richTextBlok: RichtextStoryblok,
  paragraphProps?: ParagraphProps,
) => render(richTextBlok, {
  nodeResolvers: {
    [NODE_HEADING]: (children, { level }) => {
      const heading: TypographyProps['variant'] = `h${ level }`;
      return <MuiTypography variant={ heading } className="rich-text-heading">{ children }</MuiTypography>;
    },
    [NODE_PARAGRAPH]: (children) => (
      <Paragraph
        variant="body2"
        className="RichText-paragraph"
        css={ styles.Paragraph }
        { ...paragraphProps }
      >
        { children }
      </Paragraph>
    ),
    [NODE_UL]: (children: ReactNode) => (<ul css={ styles.UList }>{ children }</ul>),
    [NODE_LI]: (children: ReactNode) => (<li css={ styles.ListItem }>{ children }</li>),
  },
  markResolvers: {
    [MARK_LINK]: (children, { href }) => ((<TdLink href={ href } target={ '_blank' }>{ children }</TdLink>)),
  },
  defaultBlokResolver: (name, props) => {
    const blok = { ...props, component: name };
    return <StoryblokComponent blok={ blok } key={ props._uid } />;
  },
});
