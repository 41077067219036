import type { NavTabData } from '@/types/header.types';

export const NavTabs: NavTabData[] = [
  {
    title: 'Product',
    dropdownMode: 'auto',
    navColumns: [
      {
        title: 'How it works',
        items: [
          {
            itemName: 'Feature overview',
            href: '/features',
          },
          {
            itemName: 'Integrations',
            href: '/integrations-and-addons',
          },
          {
            itemName: 'Get started',
            href: '/get-started',
          },
        ],
      },
      {
        title: 'Use case',
        items: [
          {
            itemName: 'Productivity Analytics',
            href: '/productivity-analytics',
          },
          {
            itemName: 'Employee Monitoring',
            href: '/features/employee-monitoring',
          },
          {
            itemName: 'Workforce Analytics',
            href: '/workforce-analytics',
          },
          {
            itemName: 'Distributed Workforce',
            href: '/distributed-workforce',
          },
        ],
      },
    ],
  },
  {
    title: 'Solutions',
    dropdownMode: 'auto',
    navColumns: [
      {
        title: 'Industry',
        items: [
          {
            itemName: 'CX & Contact Center',
            href: '/contact-centers',
          },
          {
            itemName: 'BPO & KPO',
            href: '/bpo-outsourcing',
          },
          {
            itemName: 'Staff Leasing',
            href: '/staff-leasing',
          },
          {
            itemName: 'Technology Companies',
            href: '/technology-companies',
          },
          {
            itemName: 'Agencies',
            href: '/increase-agency-efficiency',
          },
        ],
      },
      {
        title: 'Workforce',
        items: [
          {
            itemName: 'Remote',
            href: '/remote-work-solutions',
          },
          {
            itemName: 'Hybrid',
            href: '/hybrid-workforce-model',
          },
          {
            itemName: 'In-Office',
            href: '/in-office-solutions',
          },
          {
            itemName: 'Enterprise',
            href: '/enterprise-ready-software',
          },
        ],
      },
    ],
  },
  {
    title: 'Pricing',
    mode: 'link',
    href: '/pricing',
    navColumns: [],
  },
  {
    title: 'Resources',
    dropdownMode: 'auto',
    navColumns: [
      {
        items: [
          {
            itemName: 'Resources Hub',
            href: 'https://resources.timedoctor.com/',
          },
          {
            itemName: 'Case studies',
            href: 'https://resources.timedoctor.com/case-studies/?_ga=2.126937741.1483065531.1718718719-2006643664.1692711516',
          },
          {
            itemName: 'Blog',
            href: 'https://www.timedoctor.com/blog/',
          },
          {
            itemName: 'Webinars',
            href: 'https://resources.timedoctor.com/category/webinars/',
          },
          {
            itemName: 'Guides',
            href: 'https://resources.timedoctor.com/category/insights/',
          },
          {
            itemName: 'Podcasts',
            href: 'https://resources.timedoctor.com/category/podcasts/',
          },
        ],
      },
    ],
  },
];
