import type { AriaRole, KeyboardEvent as ReactKeyboardEvent } from 'react';

const navigateForward = (evt: KeyboardEvent) => {
  const target = evt.target as HTMLElement;
  target.setAttribute('tabindex', '-1');

  const liElm = evt.composedPath().find(target => target instanceof HTMLLIElement) as HTMLElement;
  const nextMenuItem = liElm?.nextElementSibling?.querySelector('[role="menuitem"][tabindex="-1"]') as HTMLElement;

  if (!nextMenuItem) {
    const ulElm = evt.composedPath().find(target => target instanceof HTMLUListElement) as HTMLElement;
    const firstMenuItem = ulElm.firstElementChild?.querySelector('[role="menuitem"]') as HTMLElement;
    focusItem(firstMenuItem);
  } else {
    focusItem(nextMenuItem);
  }

  evt.preventDefault();
};

const navigateBackward = (evt: KeyboardEvent) => {
  const target = evt.target as HTMLElement;
  target.setAttribute('tabindex', '-1');

  const liElm = evt.composedPath().find(target => target instanceof HTMLLIElement) as HTMLElement;
  const prevMenuItem = liElm?.previousElementSibling?.querySelector('[role="menuitem"][tabindex="-1"]') as HTMLElement;

  if (!prevMenuItem) {
    const ulElm = evt.composedPath().find(target => target instanceof HTMLUListElement) as HTMLElement;
    const lastMenuItem = ulElm.lastElementChild?.querySelector('[role="menuitem"]') as HTMLElement;
    focusItem(lastMenuItem);
  } else {
    focusItem(prevMenuItem);
  }

  evt.preventDefault();
};

const findNextFirstMenuItem = (evt: KeyboardEvent, parentRole: AriaRole) => {
  const menuElm = evt.composedPath().find(
    evTarget =>
      evTarget instanceof HTMLElement
      && evTarget.getAttribute('role') === parentRole,
  ) as HTMLElement;

  return menuElm.nextElementSibling?.querySelector('[role="menuitem"]') as HTMLElement;
};

const findPrevFirstMenuItem = (evt: KeyboardEvent, parentRole: AriaRole) => {
  const menuElm = evt.composedPath().find(
    evTarget =>
      evTarget instanceof HTMLElement
      && evTarget.getAttribute('role') === parentRole,
  ) as HTMLElement;

  return menuElm?.previousElementSibling?.querySelector('[role="menuitem"]') as HTMLElement;
};

const megaMenuNavigation = (direction: 'forward' | 'backward', evt: ReactKeyboardEvent) => {
  evt.stopPropagation();
  evt.preventDefault();

  const elmToFocus = direction === 'forward'
    ? findNextFirstMenuItem(evt.nativeEvent, 'group')
    : findPrevFirstMenuItem(evt.nativeEvent, 'group');

  if (elmToFocus) {
    focusItem(elmToFocus);
    (evt.target as HTMLElement).setAttribute('tabindex', '-1');
  }
};

const resetTabIndex = (target: HTMLElement, parentElm: HTMLElement) => {
  target.setAttribute('tabindex', '-1');
  parentElm.querySelector('[role="menuitem"]')
    ?.setAttribute('tabindex', '0');
};

const focusItem = (elm: HTMLElement) => {
  elm.setAttribute('tabindex', '0');
  elm.focus();
};

const keyboardNavUtil = {
  findNextFirstMenuItem,
  focusItem,
  navigateForward,
  navigateBackward,
  megaMenuNavigation,
  resetTabIndex,
};

export default keyboardNavUtil;
