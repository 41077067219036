import type React from 'react';
import MuiFacebookIcon from '@mui/icons-material/Facebook';
import MuiInstagramIcon from '@mui/icons-material/Instagram';
import MuiLinkedInIcon from '@mui/icons-material/LinkedIn';
import MuiYouTubeIcon from '@mui/icons-material/YouTube';
import Image from 'next/image';

import FooterNavColumn from '@/components/shared/footer/FooterNavColumn';
import TdLink from '@/components/uikit/link/TdLink';
import {
  footerCompanyItems,
  footerHelpItems,
  footerIndustryItems,
  footerProductItems,
  footerResourcesItems,
  footerUseCasesItems,
  footerWorkforceTypeItems,
} from '@/shared/footer/footer-link';

import { styles } from './Footer.styles';


const FooterWeb = (): React.ReactElement => (
  <div css={ styles.Root }>
    { /* Products */ }
    <div css={ styles.ListItem }>
      <FooterNavColumn title="Products">
        { footerProductItems.map(({ titleName, href, target, rel, id }, index) => (
          <li key={ index }>
            <TdLink id={ id || '' } href={ href } target={ target || '' } rel={ rel || '' }>{ titleName }</TdLink>
          </li>
        )) }
      </FooterNavColumn>
    </div>

    { /*Use case*/ }
    <div css={ styles.ListItem }>
      <FooterNavColumn title="Use Case">
        { footerUseCasesItems.map(({ titleName, href, target, rel, id }, index) => (
          <li key={ index }>
            <TdLink id={ id || '' } href={ href } target={ target || '' } rel={ rel || '' }>{ titleName }</TdLink>
          </li>
        )) }
      </FooterNavColumn>
    </div>

    { /*Industry and Workforce Type*/ }
    <div css={ styles.ListContainer }>
      <div css={ styles.ListItem }>
        <FooterNavColumn title="Industry">
          { footerIndustryItems.map(({ titleName, href, target, rel, id }, index) => (
            <li key={ index }>
              <TdLink id={ id || '' } href={ href } target={ target || '' } rel={ rel || '' }>{ titleName }</TdLink>
            </li>
          )) }
        </FooterNavColumn>
      </div>

      <div css={ styles.ListItem }>
        <FooterNavColumn title="Workforce Type">
          { footerWorkforceTypeItems.map(({ titleName, href, target, rel, id }, index) => (
            <li key={ index }>
              <TdLink id={ id || '' } href={ href } target={ target || '' } rel={ rel || '' }>{ titleName }</TdLink>
            </li>
          )) }
        </FooterNavColumn>
      </div>
    </div>

    { /*Resources and Help*/ }
    <div css={ styles.ListContainer }>
      <div css={ styles.ListItem }>
        <FooterNavColumn title="Resources">
          { footerResourcesItems.map(({ titleName, href, target, rel, id }, index) => (
            <li key={ index }>
              <TdLink id={ id || '' } href={ href } target={ target || '' } rel={ rel || '' }>{ titleName }</TdLink>
            </li>
          )) }
        </FooterNavColumn>
      </div>

      <div css={ styles.ListItem }>
        <FooterNavColumn title="Help">
          { footerHelpItems.map(({ titleName, href, target, rel, id }, index) => (
            <li key={ index }>
              <TdLink id={ id || '' } href={ href } target={ target || '' } rel={ rel || '' }>{ titleName }</TdLink>
            </li>
          )) }
        </FooterNavColumn>
      </div>
    </div>

    { /*Company & Icons*/ }
    <div css={ styles.ListContainer }>
      <div css={ styles.ListItem }>
        <FooterNavColumn title="Company">
          { footerCompanyItems.map(({ titleName, href, target, rel, id }, index) => (
            <li key={ index }>
              <TdLink id={ id || '' } href={ href } target={ target || '' } rel={ rel || '' }>{ titleName }</TdLink>
            </li>
          )) }
        </FooterNavColumn>
      </div>

      <div css={ styles.IconSection }>
        <TdLink href="https://www.linkedin.com/company/time-doctor/">
          <MuiLinkedInIcon sx={{ color: '#ffffff' }} fontSize={ 'large' } />
        </TdLink>
        <TdLink href="https://www.facebook.com/TimeDoctorSoftware/">
          <MuiFacebookIcon sx={{ color: '#ffffff' }} fontSize={ 'large' } />
        </TdLink>
        <TdLink href="https://www.instagram.com/timedoctorapp/">
          <MuiInstagramIcon sx={{ color: '#ffffff' }} fontSize={ 'large' } />
        </TdLink>
        <TdLink href="https://x.com/manageyourtime">
          <Image
            width={ 24 } height={ 32 }
            src="/_static/svg/twitter-x-icon.svg"
            alt="X Icon" />
        </TdLink>
        <TdLink href="https://www.youtube.com/c/Timedoctor-Manage-Your-Time">
          <MuiYouTubeIcon sx={{ color: '#ffffff' }} fontSize={ 'large' } />
        </TdLink>
      </div>
    </div>


  </div>
);

export default FooterWeb;
