import type { FC, FocusEventHandler } from 'react';
import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import type { Theme } from '@mui/material/styles';
import type { CountryCode } from 'libphonenumber-js';

import { FormInputCtx } from '@/components/uikit/Input';
import type { SignUpFormData } from '@/types/signup.types';
import deviceDetector from '@/utils/device-detector.util';
import trackingUtils from '@/utils/tracking.util';

const useCountryCode = () => {
  const [countryCode, setCountryCode] = useState<string | undefined>();

  useEffect(() => {
    deviceDetector.getGeoLocData().then(({ countryCode }) => {
      const code = countryCode === 'unknown' ? 'us' : countryCode;
      setCountryCode(code);
    });
  }, []);

  return {
    countryCode,
  };
};

const styles = {
  Label: (theme: Theme) => css({
    marginTop: '8px',
    textAlign: 'left',
    fontSize: theme.typography.body3.fontSize,
    fontWeight: theme.typography.body3.fontWeight,
    color: '#342B27',
    lineHeight: theme.typography.body3.lineHeight,
  }),
  FormControl: css`
    width: 100%;
  `,
}

export const PhoneNumberInput: FC = () => {
  const {
    register,
    trigger,
    setValue,
    getFieldState,
  } = useFormContext<SignUpFormData>();
  const { onBlur, ...allRegister } = register('phoneNumber', { value: '', shouldUnregister: true });
  const { touchedFields, setTouchedFields } = useContext(FormInputCtx);
  const { countryCode } = useCountryCode();

  const { phoneNumber: isTouched } = touchedFields;
  const { error } = getFieldState('phoneNumber');

  useEffect(() =>
  // Unregister the input when the component is unmounted
    () => {
      setTouchedFields((prev) => ({ ...prev, phoneNumber: false }));
    }
  , []);

  const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    trigger('phoneNumber');
    onBlur(event);
    setTouchedFields((prev) => ({ ...prev, phoneNumber: true }));
    trackingPhoneNumberEngagement(event.target.value);
  };

  const trackingPhoneNumberEngagement = (phone: string) => {
    trackingUtils.trackEvent({
      event: 'Phone Number Engagement',
      category: 'NonHomePage',
      properties: {
        phonenumber: phone,
      },
    });
  };

  const handlePhoneNumberChange = (phoneNumber: string) => {
    setTouchedFields((prev) => ({ ...prev, phoneNumber: false }));
    setValue('phoneNumber', phoneNumber);
    trigger('phoneNumber');
  };

  return (
    <>
      <InputLabel css={ styles.Label }>Phone Number</InputLabel>
      <FormControl css={ styles.FormControl }>
        <StyledPhoneInput
          { ...allRegister }
          state={ !isTouched ? 'pristine' : error?.message ? 'invalid' : 'valid' }
          placeholder="Enter phone number"
          international={ true }
          onChange={ handlePhoneNumberChange }
          onBlur={ handleBlur }
          defaultCountry={ countryCode ? countryCode as CountryCode : '' as CountryCode }
        />
        <FormHelperText
          sx={{ marginTop: '-12px', marginLeft: '0', marginBottom: '12px' }}
          className="Mui-error"
        >
          { (isTouched && error?.message) || ' ' }
        </FormHelperText>
      </FormControl>
    </>
  );
};

const StyledPhoneInput = styled(PhoneInput, {
  shouldForwardProp: (prop) => prop !== 'state',
})<{ state: 'pristine' | 'invalid' | 'valid' }>(({ theme, state }) => css`
  height: 40px;
  border: 1px solid #B3AEAB;
  border-radius: 15px;
  padding-left: 14px;
  margin: 8px 0 14px 0;
  color: #B3AEAB;
  font-size: ${ theme.typography.body3.fontSize };
  font-weight: ${ theme.typography.body3.fontWeight };
  line-height: ${ theme.typography.body3.lineHeight };
  background: #FFFFFF;

  &.PhoneInput--focus {
    border-color: ${ theme.palette.info.main };
  }

  border-color: ${ state === 'pristine'
    ? theme.palette.neutral.lightest
    : state === 'invalid' ? theme.palette.error.main : theme.palette.primary.main };
`);
