import type React from 'react';

import { styles } from '@/shared/footer/Footer.styles';

import FooterContent from './footer-web';

const Footer: React.FC<{ onClick?: (evt: React.MouseEvent) => void }> =
  ({ onClick }) => (
    <footer onClick={ onClick }>
      <div css={ styles.FooterContainer }>
        <FooterContent />
      </div>
    </footer>
  );
export default Footer;

