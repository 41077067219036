import type { FC } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import type { SbBlokData } from '@storyblok/js';
import { storyblokEditable } from '@storyblok/react';

import type { LinkButtonProps } from '@/components/uikit/link-button/LinkButton';
import LinkButtonCustom from '@/components/uikit/link-button-custom/LinkButtonCustom';
import type { ButtonCustomStoryblok, IconStoryblok } from '@/sb-types';


type ButtonBlokProps = {
  blok: SbBlokData<ButtonCustomStoryblok>;
}

type UserStyles = Pick<ButtonCustomStoryblok, 'marginObj' | 'customColor' | 'bgColor' | 'paddingObj' | 'sizeObj' | 'borderRadiusObj'>

const propsToForward: (keyof LinkButtonProps)[] = [
  'noHover',
  'variant',
  'textUnderline',
  'color',
];

const IconSvg = styled('span')<Omit<IconStoryblok, 'iconSvg'>>(({ iconColor, marginObj, sizeObj, theme }) => ({
  display: 'inline-flex',

  '& svg': {
    width: '100%',
    height: 'auto',

    '& path': {
      fill: 'currentColor',
    },
  },

  ...marginObj,
  ...sizeObj,
  ...iconColor,
  ...theme.utils.objStyleBreakpoints(marginObj, sizeObj, iconColor),
}));

const StyledLinkButton = styled(LinkButtonCustom, {
  shouldForwardProp: (prop) => isPropValid(prop) || propsToForward.includes(prop as keyof LinkButtonProps),
})<UserStyles>(({ marginObj, theme, sizeObj, bgColor, borderRadiusObj, paddingObj }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  fontWeight: 600,
  padding: '15px',
  fontSize: '20px',

  ...marginObj,
  ...sizeObj,
  ...borderRadiusObj,
  ...bgColor,
  ...paddingObj,
  ...theme.utils.objStyleBreakpoints(marginObj, sizeObj, borderRadiusObj, bgColor, paddingObj),
}));

export const ButtonCustomBlok: FC<ButtonBlokProps> = ({ blok }) => (
  <StyledLinkButton
    href={ blok.link?.url || blok.link?.cached_url || '#' }
    color={ blok.colorButton || 'primaryCustom' }
    noHover={ !blok.hover } textUnderline={ blok.hasUnderline }
    { ...storyblokEditable(blok) }
    { ...blok }
  >
    { blok.startIconSvg && (
      <IconSvg style={{ 'padding': '0 5px 0 0' }} dangerouslySetInnerHTML={{ __html: blok.startIconSvg }} />
    ) }
    { blok.label }
    { blok.endIconSvg && (
      <IconSvg style={{ 'padding': '0 0 0 5px' }} dangerouslySetInnerHTML={{ __html: blok.endIconSvg }} />
    ) }
  </StyledLinkButton>
)
