import type { FC } from 'react';
import { css, Global } from '@emotion/react';
import { ThemeProvider } from '@mui/material';
import type { SbBlokData } from '@storyblok/js';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import { useTrackPage } from '@/hooks';
import { Layout } from '@/layouts/Layout';
import type { DefaultPageStoryblok } from '@/sb-types';

import { web3Theme } from '../../../theme';

type DefaultPageBlokProps = {
  blok: SbBlokData<DefaultPageStoryblok>;
}

const globalStyles = css`
  html {
    scroll-behavior: smooth;
  }
`;

const DefaultPageBlok: FC<DefaultPageBlokProps> = ({ blok }) => {
  useTrackPage({ ...blok });

  return (
    <>
      <Global styles={ globalStyles } />
      <Layout
        pageName={ blok.trackingName } pageCategory={ blok.pageCategory } pageTitle={ blok.meta?.title }
        pageDesc={ blok.meta?.description }
        withFooter={ blok.withFooter } withHeader={ blok.withHeader } { ...storyblokEditable(blok) }>
        <ThemeProvider theme={ web3Theme }>
          { blok?.body?.map(nestedBlok => (
            <StoryblokComponent blok={ nestedBlok } key={ nestedBlok._uid } />
          )) }
        </ThemeProvider>
      </Layout>
    </>
  );
};

export default DefaultPageBlok;
