type FooterItemType = {
  titleName: string | React.ReactNode;
  href: string;
  rel?: string;
  target?: string;
  id?: string;
};

export const footerProductItems: FooterItemType[] = [
  {
    titleName: 'Features',
    href: '/features',
  },
  {
    titleName: 'Pricing',
    href: '/pricing',
  },
  {
    titleName: 'Integrations',
    href: '/integrations-and-addons',
  },
  {
    titleName: 'Get started',
    href: '/get-started',
  },
];

export const footerUseCasesItems: FooterItemType[] = [
  {
    titleName: 'Productivity Analytics',
    href: '/productivity-analytics',
  },
  {
    titleName: 'Employee Monitoring',
    href: '/features/employee-monitoring',
  },
  {
    titleName: 'Workforce Analytics',
    href: '/workforce-analytics',
  },
  {
    titleName: 'Distributed Workforce',
    href: '/distributed-workforce',
  },
  {
    titleName: 'Time Tracking',
    href: '/employee-time-tracking',
  },
  {
    titleName: 'Payroll',
    href: '/feature-payroll-integration',
  },
  {
    titleName: 'Time Management',
    href: '/time-management-with-time-doctor',
  },
  {
    titleName: 'Screen Monitoring',
    href: '/screen-monitoring-software',
  },
  {
    titleName: 'Attendance',
    href: '/employee-attendance-tracking',
  },
  {
    titleName: 'Accountability',
    href: '/drive-accountability',
  },
  {
    titleName: 'Profitability',
    href: '/increase-profitability',
  },
  {
    titleName: 'Empowering Employees',
    href: '/empowers-employees',
  },
];

export const footerIndustryItems: FooterItemType[] = [

  {
    titleName: 'CX & Contact Center',
    href: '/contact-centers',
  },
  {
    titleName: 'BPO & KPO',
    href: '/bpo-outsourcing',
  },
  {
    titleName: 'Staff Leasing',
    href: '/staff-leasing',
  },
  {
    titleName: 'Technology Companies',
    href: '/technology-companies',
  },
  {
    titleName: 'Agencies',
    href: '/increase-agency-efficiency',
  },
];

export const footerWorkforceTypeItems: FooterItemType[] = [
  {
    titleName: 'Remote',
    href: '/remote-work-solutions',
  },
  {
    titleName: 'Hybrid',
    href: '/hybrid-workforce-model',
  },
  {
    titleName: 'In-Office',
    href: '/in-office-solutions',
  },
  {
    titleName: 'Enterprise',
    href: '/enterprise-ready-software',
  },
];

export const footerResourcesItems: FooterItemType[] = [
  {
    titleName: 'Resources Hub',
    href: 'https://www.timedoctor.com/resources',
  },
  {
    titleName: 'Case studies',
    href: 'https://resources.timedoctor.com/case-studies/?_ga=2.126937741.1483065531.1718718719-2006643664.1692711516',
  },
  {
    titleName: 'Blog',
    href: 'https://www.timedoctor.com/blog/',
  },
  {
    titleName: 'Webinars',
    href: 'https://resources.timedoctor.com/category/webinars/',
  },
  {
    titleName: 'Guides',
    href: 'https://resources.timedoctor.com/category/insights/',
  },
  {
    titleName: 'Podcasts',
    href: 'https://resources.timedoctor.com/category/podcasts/',
  },
  {
    titleName: 'Benchmarking Tool',
    href: 'https://benchmark.timedoctor.com/?_ga=2.232386906.45734678.1665098714-1075295133.1665098714',
  },
];

export const footerHelpItems: FooterItemType[] = [
  {
    titleName: 'Contact us',
    href: '/contact',
  },
  {
    titleName: 'Help Center',
    href: 'https://support.timedoctor.com/knowledge',
  },

  {
    titleName: 'FAQs',
    href: 'https://support.timedoctor.com/knowledge/faq',
  },
  {
    titleName: 'Uptime Status',
    href: 'https://status.timedoctor.com/',
  },
];

export const footerCompanyItems: FooterItemType[] = [
  {
    titleName: 'About us',
    href: '/about-us',
  },
  {
    titleName: 'Jobs',
    href: 'https://www.timedoctor.com/jobs',
  },
  {
    titleName: 'Partner Program',
    href: 'https://partner.timedoctor.com/',
  },
  {
    titleName: 'Privacy Policy',
    href: 'https://www.timedoctor.com/privacy_policy',
  },
  {
    titleName: 'Terms of Service',
    href: 'https://www.timedoctor.com/terms_of_service',
  },
];
