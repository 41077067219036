import type { FC } from 'react';
import { css, Global } from '@emotion/react';

import { LinkTrackCtxProvider } from '@/components/uikit/link/TdLink';
import { useScrollHandler } from '@/hooks';
import Footer from '@/shared/footer';
import Header from '@/shared/header/Header';
import RenderIf from '@/shared/render-if/RenderIf';

import type { BaseLayoutProps } from './BaseLayout/BaseLayout';
import { BaseLayout } from './BaseLayout/BaseLayout';

export interface LayoutProps extends BaseLayoutProps {
  pageName: string;
  pageCategory: string;

  className?: string;
  withHeader?: boolean;
  withFooter?: boolean;
}

const marketingBannerHeight = process.env.NEXT_PUBLIC_MARKETING_BANNER_HEIGHT || '0px';
const globalStyles = css`
  :root {
    --marketing-banner-height: ${ marketingBannerHeight };
  }
`;

const styles = {
  Main: ({ withHeader }: Partial<LayoutProps>) => css`
    --padding-top: var(--main-header-height-mobile);

    padding-top: ${ withHeader ? 'var(--padding-top)' : 0 };
  `,
};

export const Layout: FC<LayoutProps> = ({
  children,
  keywords,
  pageCategory,
  pageDesc,
  pageName,
  pageTitle,
  className,
  withHeader = true,
  withFooter = true,
  ...restProps
}) => {
  useScrollHandler(pageCategory);

  return (
    <BaseLayout pageDesc={ pageDesc } pageTitle={ pageTitle } keywords={ keywords }>
      <Global styles={ globalStyles } />
      <RenderIf condition={ withHeader }>
        <Header pageName={ pageName } />
      </RenderIf>

      <LinkTrackCtxProvider value={{
        location: 'section',
        category: pageCategory,
      }}>
        <main className={ className || '' } css={ styles.Main({ withHeader }) } { ...restProps }>{ children }</main>
      </LinkTrackCtxProvider>

      <RenderIf condition={ withFooter }>
        <LinkTrackCtxProvider value={{
          location: 'footer',
          category: pageCategory,
        }}>
          <Footer />
        </LinkTrackCtxProvider>
      </RenderIf>
    </BaseLayout>
  );
};
