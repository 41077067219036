import type { AnchorHTMLAttributes, FC } from 'react';
import classNames from 'classnames';

import TdLink from '@/components/uikit/link/TdLink';

import styles from './TdLogo.module.scss';

type TdLogoProps = {
  width: number;
  height: number;
  smallOnMobile?: boolean;
} & AnchorHTMLAttributes<unknown>

const TdLogo: FC<TdLogoProps> = ({ width, height, smallOnMobile, className, ...all }) => (
  <TdLink
    href="/"
    linkName="logo" linkType="img"
    className={ classNames(styles.TdLogo, className, {
      [styles.SmallMobile]: smallOnMobile,
    }) }
    style={{ width, height }}
    aria-label="Time Doctor logo"
    { ...all }
  />
);

export default TdLogo;
