import type { FC } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { SbBlokData } from '@storyblok/js';
import { storyblokEditable } from '@storyblok/react';
import type { ImageProps } from 'next/image';
import NextImage from 'next/image';

import type { ImageStoryblok } from '@/sb-types';
import RenderIf from '@/shared/render-if/RenderIf';

type StoryBlokProps = {
  blok: SbBlokData<ImageStoryblok>;
}

type UserStyles = Pick<ImageStoryblok, 'sizeObj' | 'positionObj' | 'marginObj' | 'paddingObj' | 'flags' | 'borderRadiusObj' | 'config' | 'boxShadow' | 'zIndex' | 'withBoxShadow' | 'withBorderRadius'>

const propsToForward: (keyof ImageProps)[] = ['quality'];

const StyledTdImage = styled(NextImage, {
  shouldForwardProp: (prop) => isPropValid(prop) || propsToForward.includes(prop as keyof ImageProps),
})<UserStyles>(
  ({
    boxShadow,
    zIndex,
    config,
    borderRadiusObj,
    sizeObj,
    positionObj,
    marginObj,
    paddingObj,
    flags,
    theme,
    withBoxShadow,
    withBorderRadius,
  }) => css({
    width: '100%',
    height: 'auto',
    boxShadow: withBoxShadow ? '0px 13px 30px 0px rgba(0, 0, 0, 0.20)' : boxShadow,
    borderRadius: withBorderRadius ? '25px' : undefined,
    zIndex,

    ...positionObj,
    ...marginObj,
    ...paddingObj,
    ...sizeObj,
    ...(!withBorderRadius && borderRadiusObj),
    ...flags,
    ...config,

    ...theme.utils.objStyleBreakpoints(positionObj, marginObj, paddingObj, sizeObj, flags, config, borderRadiusObj),
  }),
);

export const ImageBlok: FC<StoryBlokProps> = ({ blok: { quality, enableStoryblokFilter = true, ...restBlok }}) => {
  const imgFilterUrl = enableStoryblokFilter ? `/m/filters:quality(${ quality })` : '';

  return (
    <RenderIf condition={ !!restBlok.image.filename }>
      <StyledTdImage
        id={ restBlok._uid }
        src={ `${ restBlok.image.filename }${ imgFilterUrl }` } alt={ restBlok.image.alt || '' }
        width={ restBlok.image.meta_data.width } height={ restBlok.image.meta_data.height }
        quality={ 100 }
        { ...restBlok }
        { ...storyblokEditable(restBlok) }
      />
    </RenderIf>
  );
};
