import type { ThemeOptions } from '@mui/material';
import { createTheme } from '@mui/material';

import { breakpoints } from './breakpoints.theme';
import { components } from './components.theme';
import { palette } from './palette.theme';
import { shadows } from './shadows.theme';
import { typography, web3Typography } from './typography.theme';
import { utils } from './utils.theme';

const themeOptions: ThemeOptions = {
  palette,
  typography,
  components,
  breakpoints,
  utils,
  shadows: shadows as unknown as ThemeOptions['shadows'],
};

const web3ThemeOptions: ThemeOptions = {
  ...themeOptions,
  typography: web3Typography,
};

export const theme = createTheme(themeOptions);
export const web3Theme = createTheme(web3ThemeOptions);
